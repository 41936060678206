import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const IsItPossibleToCreateAScratchCampaign = ({ location }) => {
  const title = "Can you change the design of Instant Win results?"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="By making the winning result a game-like performance such as slots or scratch-offs, the campaign can be conducted with a higher appealing effect. This can make participants more intensely aware of the brand than in a normal campaign."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Is it possible to customize the presentation of the screen
              displaying the winning results?
            </h1>
            <p className="qa-page__answer">
              Yes, this is an option, but we can handle it.
              <br />
            </p>
            <p className="qa-page__text">
              The production can display images and characters that match the
              brand's image and impress participants with the name of the
              product or service.
            </p>
            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  Flow from participation in the campaign to receipt of goods in
                  the case of customized staging
                </h2>

                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>
                      Join the campaign by retweeting (other methods of
                      participation are also possible)
                    </li>
                    <li>Notification of results via direct message</li>
                    <li>
                      Transition to the campaign site from the URL in the direct
                      message
                    </li>
                    <li>
                      Displaying the production in the site (videos, animations,
                      games, slides etc ...)
                    </li>
                    <li>
                      After the production is completed, the result of winning
                      or losing is displayed.
                    </li>
                    <li>Receive prizes if you win</li>
                  </ol>
                </div>

                <h3 className="qa-page__h3">
                  Please feel free to contact us for design and proposal of
                  customization of the staging area.
                </h3>

                <p className="qa-page__text">
                  Campaigns can also be designed to allow participants to
                  participate in double chances by answering questionnaires
                  after the results are displayed.
                </p>

                <p className="qa-page__text">
                  As for the customization of the production, it is an optional
                  feature of PARKLoT, so please contact us for more details.
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default IsItPossibleToCreateAScratchCampaign
